/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import PaymentLayout from './layouts/Payment/PaymentLayout';

export default [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/add/app" />
    },
    {
        path: '/add',
        component: PaymentLayout,
        routes: [
            {
                path: '/add/app',
                exact: true,
                component: lazy(() => import('src/views/Bexio/AddApplication'))
            },
            {
                path: '/add/widget',
                exact: true,
                component: lazy(() => import('src/views/Bexio/AddBrowserWidget'))
            }
        ]
    },
    {
        path: '/payment',
        component: PaymentLayout,
        routes: [
            {
                path: '/payment/verify',
                exact: true,
                component: lazy(() => import('src/views/payment/Verify'))
            },
            {
                path: '/payment/success',
                exact: true,
                component: lazy(() => import('src/views/payment/Success'))
            },
            {
                path: '/payment/error',
                exact: true,
                component: lazy(() => import('src/views/payment/Error'))
            },
            {
                path: '/payment/plans',
                exact: true,
                component: lazy(() => import('src/views/Bexio/SubscriptionPlans'))
            }
        ]
    },
    {
        path: '/auth',
        component: AuthLayout,
        routes: [
            {
                path: '/auth/login',
                exact: true,
                component: lazy(() => import('src/views/Login'))
            },
            {
                path: '/auth/sign-up',
                exact: true,
                component: lazy(() => import('src/views/SignUp'))
            },
            {
                path: '/auth/forgot-password',
                exact: true,
                component: lazy(() => import('src/views/ForgotPassword'))
            },
            {
                path: '/auth/reset-password',
                exact: true,
                component: lazy(() => import('src/views/ResetPassword'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '/errors',
        component: ErrorLayout,
        routes: [
            {
                path: '/errors/error-401',
                exact: true,
                component: lazy(() => import('src/views/Error401'))
            },
            {
                path: '/errors/error-404',
                exact: true,
                component: lazy(() => import('src/views/Error404'))
            },
            {
                path: '/errors/error-403',
                exact: true,
                component: lazy(() => import('src/views/Error403'))
            },
            {
                path: '/errors/error-423',
                exact: true,
                component: lazy(() => import('src/views/Error423'))
            },
            {
                path: '/errors/error-500',
                exact: true,
                component: lazy(() => import('src/views/Error500'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        route: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/management/user',
                exact: true,
                component: lazy(() => import('src/views/management/user/Users')),
            },
            {
                path: '/transactions',
                exact: true,
                component: lazy(() => import('src/views/payment/history/PaymentHistory')),
            },
            {
                path: '/management/article',
                exact: true,
                component: lazy(() => import('src/views/management/article/Article'))
            },
            {
                path: '/management/stock-quantity/:tab',
                exact: true,
                component: lazy(() => import('src/views/management/article/StockQuantity')),
            },
            {
                path: '/profile',
                exact: true,
                component: lazy(() => import('src/views/profile/Profile'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    }
];