import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import AuthGuard, { ROLE_ADMIN, ROLE_USER } from 'src/components/AuthGuard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
        display: 'flex',
        '@media all and (-ms-high-contrast:none)': {
            height: 0 // IE11 fix
        }
    },
    content: {
        paddingTop: 64,
        flexGrow: 1,
        maxWidth: '100%',
        overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 56
        }
    },
    freeTrialBanner: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        width: '100%',
        background: '#4051b5',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        },
        '& h4': {
            color: 'white'
        },
        '& button': {
            marginLeft: '20px',
            color: 'white',
            borderColor: 'white'
        }
    }
}));

function Dashboard({ route }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const session = useSelector(state => state.session);
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

    return (
        <>
            <AuthGuard roles={[ROLE_ADMIN, ROLE_USER]}>
                <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
                <NavBar
                    onMobileClose={() => setOpenNavBarMobile(false)}
                    openMobile={openNavBarMobile}
                />
                <div className={classes.container}>
                    <div className={classes.content}>
                        <Suspense fallback={<LinearProgress />}>
                            {renderRoutes(route.routes)}
                        </Suspense>
                    </div>
                    {session.user.isTrial
                        ? (
                            <div className={classes.freeTrialBanner}>
                                <Typography variant='h4'>
                                    {t('You are using a test subscription plan!')}
                                    <Button
                                        color="secondary"
                                        onClick={() => console.log()}
                                        variant="outlined">
                                        {t('Order subscription')}
                                    </Button>
                                </Typography>
                            </div>
                        )
                        : <></>}
                </div>
            </AuthGuard>
        </>
    );
}

Dashboard.propTypes = {
    route: PropTypes.object
};

export default Dashboard;
