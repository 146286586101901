/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import PeopleIcon from '@material-ui/icons/People';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { AiOutlineStock } from "react-icons/ai";
import { ROLE_ADMIN, ROLE_USER } from 'src/components/AuthGuard';
import ExtensionIcon from '@material-ui/icons/Extension';

export default [
    {
        subheader: 'User Management',
        roles: [ROLE_ADMIN],
        items: [
            {
                title: 'Users',
                href: '/management/user',
                icon: PeopleIcon
            }
        ]
    },
    {
        subheader: 'Article Management',
        items: [
            {
                title: 'Articles',
                href: '/management/article',
                icon: ExtensionIcon
            },
        ]
    },
    {
        subheader: 'Transaction Management',
        roles: [ROLE_ADMIN],
        items: [
            {
                title: 'Payment History',
                href: '/transactions',
                icon: CreditCardIcon
            }
        ]
    },
    {
        subheader: 'Stock Management',
        roles: [ROLE_ADMIN, ROLE_USER],
        items: [
            {
                title: 'Stock quantity',
                href: '/management/stock-quantity/',
                icon: AiOutlineStock,
                items: [
                    {
                        title: 'By Stock',
                        href: '/management/stock-quantity/stock'
                    },
                    {
                        title: 'By Article',
                        href: '/management/stock-quantity/article'
                    },
                ]
            }
        ]
    }
];
