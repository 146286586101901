/* eslint-disable no-unused-vars */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, Hidden, IconButton, Toolbar } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import { resetAuthToken } from 'src/utils/token';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
    },
    flexGrow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(1)
    },
    logoutButton: {
        marginLeft: theme.spacing(1)
    },
    logoutIcon: {
        marginRight: theme.spacing(1)
    }
}));

function TopBar({
    onOpenNavBarMobile,
    className,
    ...rest
}) {
    const classes = useStyles();
    const session = useSelector(state => state.session);
    const { t } = useTranslation();

    const handleLogout = () => {
        resetAuthToken();
        window.location = '/auth/login';
    };

    const handleLogin = () => {
        window.location = '/auth/login';
    }

    return (
        <AppBar{...rest} className={clsx(classes.root, className)} color="primary">
            <Toolbar>
                <Hidden lgUp>
                    <IconButton className={classes.menuButton} color="inherit" onClick={onOpenNavBarMobile}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
                <RouterLink to={session.loggedIn ? '/management/user' : '/'}>
                    <img alt="Logo" width="100px" src="/images/logos/fasolit-logo.png" />
                </RouterLink>
                <div className={classes.flexGrow} />

                <LanguageSwitcher />

                <Button className={classes.logoutButton} color="inherit" onClick={session.loggedIn ? handleLogout : handleLogin}>
                    <InputIcon className={classes.logoutIcon} />
                    {session.loggedIn ? t("Sign out") : t("Sign in")}
                </Button>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
